<template>
  <div>
    <h3 class="modal__title">Change Reminders</h3>
    <loader v-if="loading" :container="true" />
    <h3>Send reminders via (check all that apply)</h3>
    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <checkbox-list v-model="selected" :options="list" />
        <div v-if="error" v-html="error" class="text-danger" />
        <div class="modal__footer">
          <button
            type="button"
            class="button button_text button_md"
            @click="onClose"
          >
            cancel
          </button>
          <button type="submit" class="button button_secondary button_md">
            submit
          </button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CheckboxList from "@/components/ui/checkboxList";
import Loader from "@/components/loader";
import { formatErrorsToString } from "@/modules/utils";
import { saveLog } from '@/modules/logger';

import {
  STORE_CHANGE_REMINDERS,
  STORE_FETCH_REMINDERS,
  POPUP_NOTIFICATION,
  MSG_REMINDERS_UPDATED,
} from "@/constants";

export default {
  components: { CheckboxList, Loader },

  mounted() {
    this.loading = true;
    this[STORE_FETCH_REMINDERS]()
      .then(() => {
        this.default_subscribed_text && this.reminders.push("text");
        this.default_subscribed_email && this.reminders.push("email");
      })
      .finally(() => {
        this.loading = false;
      });
  },

  inject: {
    showModal: { default: () => {} },
  },

  data: () => ({
    reminders: [],
    list: [
      {
        value: "text",
        text: "Text",
      },
      { value: "email", text: "Email" },
    ],
    error: null,
    loading: false,
  }),

  computed: {
    ...mapState({
      default_subscribed_text: ({ user }) => user.is_subscribed_text,
      default_subscribed_email: ({ user }) => user.is_subscribed_email,
    }),

    selected: {
      get() {
        return this.reminders;
      },
      set(arr) {
        this.reminders = arr;
      },
    },
  },

  methods: {
    ...mapActions([STORE_CHANGE_REMINDERS, STORE_FETCH_REMINDERS]),

    onSubmit() {
      this.loading = true;
      this.error = null;
      saveLog("change-reminders-submit", {
        is_subscribed_text: this.reminders.includes("text"),
        is_subscribed_email: this.reminders.includes("email"),
      });

      this[STORE_CHANGE_REMINDERS]({
        is_subscribed_text: this.reminders.includes("text"),
        is_subscribed_email: this.reminders.includes("email"),
      })
        .then(this.onSuccess)
        .catch(this.errorHandler)
        .finally(this.finalHandler);
    },

    finalHandler() {
      this.loading = false;
    },

    onClose() {
      saveLog("change-reminders-close");
      this.$emit("close");
    },

    onSuccess() {
      saveLog("change-reminders-success")
      this.onClose();
      this.showModal(POPUP_NOTIFICATION, {
        title: MSG_REMINDERS_UPDATED,
        icon: "check",
      });
    },

    errorHandler(error) {
      if (!error.response) return;
      saveLog("change-reminder-failure", {error: error.response.data});
      this.error = formatErrorsToString(error.response.data);
    },
  },
};
</script>
